import {
  FETCH_STATISTICS_USAGE_REQUEST,
  FETCH_STATISTICS_USAGE_RESOLVE,
  FETCH_STATISTICS_USAGE_REJECT,
} from '../actions/statistics';

export default function statistics(
  state = {
    statistics: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_STATISTICS_USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_STATISTICS_USAGE_RESOLVE:
      return {
        ...state,
        statistics: action.payload.statistics,
        isFetching: false,
      };
    case FETCH_STATISTICS_USAGE_REJECT:
      return {
        ...state,
        statistics: [],
        isFetching: false,
        message: action.payload[0].message,
      };
    default:
      return state;
  }
}
