exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dP95I0fD8Nf4Me2fFJtXM{position:absolute;bottom:0;left:0;right:0;font-size:-.1rem}._26pKBIAl3afzSXDEOouaAB{margin:0 auto;padding:7px 30px}._3n7LvHws1ECUs8HXwybrhg{display:inline-block;padding:0 5px}", ""]);

// exports
exports.locals = {
	"root": "_3dP95I0fD8Nf4Me2fFJtXM",
	"container": "_26pKBIAl3afzSXDEOouaAB",
	"spacer": "_3n7LvHws1ECUs8HXwybrhg"
};