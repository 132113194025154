import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Widget from '../../components/Widget';
import Icon from '../../components/Icon';
import Footer from '../../components/Footer';

import s from './Login.scss'; // eslint-disable-line
import { loginUser } from '../../actions/user';

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
  };

  static defaultProps = {
    isAuthenticated: false,
    location: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
    };
  }

  changeLogin = (event) => {
    this.setState({login: event.target.value});
  }

  changePassword = (event) => {
    this.setState({password: event.target.value});
  }

  doLogin = (e) => {
    this.props.dispatch(
      loginUser({
        login: this.state.login,
        password: this.state.password,
      }),
    );
    e.preventDefault();
  }

  render() {
    const {from} = this.props.location.state || {
      from: {pathname: '/app'},
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }

    return (
        <div className={s.root}>
          <Row>
            <Col xs={{size: 10, offset: 1}} sm={{size: 6, offset: 3}} lg={{size:4, offset: 4}}>
              <Icon className={s.logo} glyph="logo" />
              <Widget className={s.widget}>
                <h4 className="mt-0">SkillZone Dashboard</h4>
                <p className="text-center">Login to Web App</p>
                {/* <Link to="/auth/facebook">
                  <Button color="primary" block>
                    <i className="fa fa-facebook" />
                    &nbsp;&nbsp;Login with Facebook
                  </Button>
                </Link> */}
                <Button tag={Link} to="/auth/google" target="_self" color="primary" block>
                  <i className="fa fa-google" />
                  &nbsp;&nbsp;Login with Google
                </Button>
              </Widget>
            </Col>
          </Row>
        <Footer className="text-center" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(s)(Login)));
