exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Q-uCUtgMZHAWNxiKjefcw{width:200px;position:fixed;left:0;top:0;bottom:0;background-color:#fff;color:#4d4d4d}._1LzH0LcdHCZ5iriUoxCbpC{display:flex;justify-content:center;align-items:center;height:75px;width:100%;background:linear-gradient(90deg,#eb3349,#f45c43)}._1LzH0LcdHCZ5iriUoxCbpC a img{width:140px}._3xF6Qom7VNGSiKlSh2HJcw{padding:10px 0;overflow-y:auto}", ""]);

// exports
exports.locals = {
	"root": "_1Q-uCUtgMZHAWNxiKjefcw",
	"logo": "_1LzH0LcdHCZ5iriUoxCbpC",
	"nav": "_3xF6Qom7VNGSiKlSh2HJcw"
};