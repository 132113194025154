import { request } from './helpers';

export const FETCH_STATISTICS_USAGE = 'FETCH_STATISTICS_USAGE';
export const FETCH_STATISTICS_USAGE_REQUEST = 'FETCH_STATISTICS_USAGE_REQUEST';
export const FETCH_STATISTICS_USAGE_RESOLVE = 'FETCH_STATISTICS_USAGE_RESOLVE';
export const FETCH_STATISTICS_USAGE_REJECT = 'FETCH_STATISTICS_USAGE_REJECT';

export function fetchStatisticsUsage(startDate, endDate) {
  return request({
    type: FETCH_STATISTICS_USAGE,
    query: `
    {
      statistics(startDate: "${startDate.toISOString()}", endDate: "${endDate.toISOString()}") {
        datetime
        shiftDate
        hour
        shiftWeekday
        dayIndex
        count
        duration
      }
    }`,
  });
}
