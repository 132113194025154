import {
  CREATE_POST_REQUEST,
  CREATE_POST_RESOLVE,
  CREATE_POST_REJECT,
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_RESOLVE,
  FETCH_POSTS_REJECT,
} from '../actions/posts';

export default function posts(
  state = {
    posts: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case CREATE_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_POST_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'Post created successfully',
      };
    case CREATE_POST_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case FETCH_POSTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        posts: [],
      };
    case FETCH_POSTS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        posts: action.payload.posts,
      };
    case FETCH_POSTS_REJECT:
      return {
        ...state,
        isFetching: false,
        posts: [],
        message: action.payload[0].message,
      };
    default:
      return state;
  }
}
