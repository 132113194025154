exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HtgALwJ33kf6TZ7nh44cX{display:block;position:relative;margin-bottom:30px;padding:15px 20px;background:#fff}._1b-TwJnyBlo792yW69YuKH{margin-top:0;margin-bottom:1.5rem/2;color:#444}._1b-TwJnyBlo792yW69YuKH:after{display:block;clear:both;content:\"\"}", ""]);

// exports
exports.locals = {
	"widget": "HtgALwJ33kf6TZ7nh44cX",
	"title": "_1b-TwJnyBlo792yW69YuKH"
};