import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import navigation from './navigation';
import posts from './posts';
import users from './users';
import gizmo from './gizmo';
import sessions from './sessions';
import usage from './usage';
import statistics from './statistics';

export default combineReducers({
  auth,
  runtime,
  navigation,
  posts,
  users,
  gizmo,
  sessions,
  usage,
  statistics,
});
