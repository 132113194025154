import { request } from './helpers';


export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_RESOLVE = 'CREATE_POST_RESOLVE';
export const CREATE_POST_REJECT = 'CREATE_POST_REJECT';

export function createPost(postData) {
  return request({
    type: CREATE_POST,
    query: `mutation CreatePost($input: PostInput!) {
      addPost(input: $input){
        id,
        title,
        content
      }
    }`,
    variables: {
      input: postData,
    }
  });
}


export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_RESOLVE = 'FETCH_POSTS_RESOLVE';
export const FETCH_POSTS_REJECT = 'FETCH_POSTS_REJECT';

export function fetchPosts() {
  return request({
    type: FETCH_POSTS,
    query: `{
      posts {
        id
        title
        content
        updatedAt
      }
    }`,
  });
}
