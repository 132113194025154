import {
  FETCH_PLACE_USAGE_REQUEST,
  FETCH_PLACE_USAGE_RESOLVE,
  FETCH_PLACE_USAGE_REJECT,
} from '../actions/usage';

export default function usages(
  state = {
    places: [],
    isFetching: false,
    placesTimestamp: null,
  },
  action,
) {
  switch (action.type) {
    case FETCH_PLACE_USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PLACE_USAGE_RESOLVE:
      return {
        ...state,
        places: action.payload.placeUsage,
        isFetching: false,
        placesTimestamp: new Date(),
      };
    case FETCH_PLACE_USAGE_REJECT:
      return {
        ...state,
        places: [],
        isFetching: false,
        message: action.payload[0].message,
      };
    default:
      return state;
  }
}
