import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

export const ROLES_EMPLOYEES = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'];
export const ROLES_ADMINS = ['ROLE_ADMIN', 'ROLE_SUPERADMIN'];

function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    role: store.auth.user && store.auth.user.role,
  };
}

export const SecureRoute = connect(mapStateToProps)(({ component, isAuthenticated, role, roles, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      isAuthenticated && roles.includes(role)
        ? React.createElement(component, props)
        : <Redirect
            to={{
              pathname: '/app/activate',
              state: { from: props.location },
            }}
          />}
  />);


export default SecureRoute;
