import {
  FETCH_GIZMO_NEWS_REQUEST,
  FETCH_GIZMO_NEWS_RESOLVE,
  FETCH_GIZMO_NEWS_REJECT,
  FETCH_GIZMO_ACTIVE_USER_SESSIONS_REQUEST,
  FETCH_GIZMO_ACTIVE_USER_SESSIONS_RESOLVE,
  FETCH_GIZMO_ACTIVE_USER_SESSIONS_REJECT,
  FETCH_GIZMO_USERS_REQUEST,
  FETCH_GIZMO_USERS_RESOLVE,
  FETCH_GIZMO_USERS_REJECT,
  FETCH_GIZMO_POINTS_REQUEST,
  FETCH_GIZMO_POINTS_RESOLVE,
  FETCH_GIZMO_POINTS_REJECT,
  FETCH_GIZMO_USER_STATS_REQUEST,
  FETCH_GIZMO_USER_STATS_RESOLVE,
  FETCH_GIZMO_USER_STATS_REJECT,
  FETCH_GIZMO_POINT_STATS_REQUEST,
  FETCH_GIZMO_POINT_STATS_RESOLVE,
  FETCH_GIZMO_POINT_STATS_REJECT,
} from '../actions/gizmo';

export default function posts(
  state = {
    news: [],
    activeUserSessions: [],
    users: [],
    points: [],
    userStats: [],
    pointStats: [],
    isFetching: false,
    activeUserSessionsTimestamp: null,
  },
  action,
) {
  switch (action.type) {
    case FETCH_GIZMO_NEWS_REQUEST:
      return {
        ...state,
        isFetching: true,
        news: [],
      };
    case FETCH_GIZMO_NEWS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        news: action.payload.gizmoNews,
      };
    case FETCH_GIZMO_NEWS_REJECT:
      return {
        ...state,
        isFetching: false,
        news: [],
        message: action.payload[0].message,
      };
    case FETCH_GIZMO_ACTIVE_USER_SESSIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_GIZMO_ACTIVE_USER_SESSIONS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        activeUserSessions: action.payload.gizmoActiveUserSessions,
        activeUserSessionsTimestamp: new Date(),
      };
    case FETCH_GIZMO_ACTIVE_USER_SESSIONS_REJECT:
      return {
        ...state,
        isFetching: false,
        activeUserSessions: [],
        message: action.payload[0].message,
      };
    case FETCH_GIZMO_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        users: [],
      };
    case FETCH_GIZMO_USERS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        users: action.payload.gizmoUsers,
      };
    case FETCH_GIZMO_USERS_REJECT:
      return {
        ...state,
        isFetching: false,
        users: [],
        message: action.payload[0].message,
      };
    case FETCH_GIZMO_POINTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        points: [],
      };
    case FETCH_GIZMO_POINTS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        points: action.payload.gizmoPoints,
      };
    case FETCH_GIZMO_POINTS_REJECT:
      return {
        ...state,
        isFetching: false,
        points: [],
        message: action.payload[0].message,
      };
    case FETCH_GIZMO_USER_STATS_REQUEST:
      return {
        ...state,
        isFetching: true,
        userStats: [],
      };
    case FETCH_GIZMO_USER_STATS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        userStats: action.payload.gizmoUsersStats,
      };
    case FETCH_GIZMO_USER_STATS_REJECT:
      return {
        ...state,
        isFetching: false,
        userStats: [],
        message: action.payload[0].message,
      };
    case FETCH_GIZMO_POINT_STATS_REQUEST:
      return {
        ...state,
        isFetching: true,
        pointStats: [],
      };
    case FETCH_GIZMO_POINT_STATS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        pointStats: action.payload.gizmoPointsStats,
      };
    case FETCH_GIZMO_POINT_STATS_REJECT:
      return {
        ...state,
        isFetching: false,
        pointStats: [],
        message: action.payload[0].message,
      };
    default:
      return state;
  }
}
