import { request } from './helpers';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_RESOLVE = 'FETCH_USERS_RESOLVE';
export const FETCH_USERS_REJECT = 'FETCH_USERS_REJECT';

export function fetchUsers() {
  return request({
    type: FETCH_USERS,
    query: `{
      users {
        id
        email
        role
        employeeId
        color
        status
        profile {
          displayName
          picture
        }
        logins {
          name
        }
      }
    }`,
  });
}


export const FETCH_USER_SESSIONS = 'FETCH_USER_SESSIONS';
export const FETCH_USER_SESSIONS_REQUEST = 'FETCH_USER_SESSIONS_REQUEST';
export const FETCH_USER_SESSIONS_RESOLVE = 'FETCH_USER_SESSIONS_RESOLVE';
export const FETCH_USER_SESSIONS_REJECT = 'FETCH_USER_SESSIONS_REJECT';

export function fetchUserSessions() {
  return request({
    type: FETCH_USER_SESSIONS,
    query: `{
      userSessions {
        id
        loginTimestamp
        loginIP
        accessTimestamp
        accessIP
        expire
        expireTimestamp
        status
        user {
          id
          color
          profile {
            displayName
            picture
          }
        }
      }
    }`,
  });
}


export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_RESOLVE = 'FETCH_USER_RESOLVE';
export const FETCH_USER_REJECT = 'FETCH_USER_REJECT';

export function fetchUser(id) {
  return request({
    type: FETCH_USER,
    query: `{
      user(id: "${id}") {
        id
        email
        role
        employeeId
        color
        status
        profile {
          displayName
          picture
        }
        logins {
          name
        }
      }
    }`,
  });
}


export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESOLVE = 'UPDATE_USER_RESOLVE';
export const UPDATE_USER_REJECT = 'UPDATE_USER_REJECT';

export function updateUser(postData) {
  return request({
    type: UPDATE_USER,
    query: `mutation UpdateUser($input: UserInput!) {
      updateUser(input: $input){
        id
      }
    }`,
    variables: {
      input: postData,
    }
  });
}
