import React from 'react';
import {connect} from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {withRouter, Link} from 'react-router-dom';

import Icon from '../Icon';
import LinksGroup from './LinksGroup/LinksGroup';

import s from './Sidebar.scss';

const Sidebar = (props) => (
  <nav className={s.root}>
    <header className={s.logo}>
      <Link to="/app">
        <Icon glyph="logo" />
      </Link>
    </header>
    {props.isAuthenticated && ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'].includes(props.user && props.user.role) &&
    <ul className={s.nav}>
      <LinksGroup
        header="Dashboard"
        headerLink="/app"
        glyph="dashboard"
      />
      {props.isAuthenticated && ['ROLE_SUPERADMIN'].includes(props.user && props.user.role) &&
        <React.Fragment>
          <LinksGroup
            header="Usage"
            headerLink="/app/usage"
            icon="bar-chart-o"
          />
          <LinksGroup
            header="Statistics"
            headerLink="/app/statistics"
            icon="table"
            childrenLinks={[
              {
                name: 'Usage',
                link: '/app/statistics/usage',
              },
              {
                name: 'Duration',
                link: '/app/statistics/duration',
              },
              {
                name: 'Registration',
                link: '/app/statistics/registration',
              },
              {
                name: 'Point',
                link: '/app/statistics/point',
              },
            ]}
          />
          <LinksGroup
            header="Users"
            headerLink="/app/users"
            icon="users"
          />
        </React.Fragment>
      }
      <LinksGroup
        header="Sessions"
        headerLink="/app/sessions"
        icon="database"
      />
      {props.isAuthenticated && ['ROLE_SUPERADMIN'].includes(props.user && props.user.role) &&
        <React.Fragment>
          <LinksGroup
            header="Gizmo"
            headerLink="/app/gizmo"
            icon="desktop"
            childrenLinks={[
              // {
              //   name: 'News',
              //   link: '/app/gizmo/news',
              // },
              {
                name: 'User Sessions',
                link: '/app/gizmo/user-sessions',
              },
              {
                name: 'Users',
                link: '/app/gizmo/users',
              },
              // {
              //   name: 'Points',
              //   link: '/app/gizmo/points',
              // },
              // {
              //   name: 'Statistics',
              //   link: '/app/gizmo/user-stats',
              // },
            ]}
          />
          <LinksGroup
            header="Components"
            headerLink="/app/components"
            childrenLinks={[
              {
                name: 'Dashboard',
                link: '/app/components/dashboard',
              },
              {
                name: 'Typography',
                link: '/app/components/typography',
              },
              {
                name: 'Tables Basic',
                link: '/app/components/tables',
              },
              {
                name: 'Notifications',
                link: '/app/components/notifications',
              },
              {
                name: 'Buttons',
                link: '/app/components/buttons',
              },
              {
                name: 'Charts',
                link: '/app/components/charts',
              },
              {
                name: 'Icons',
                link: '/app/components/icons',
              },
              {
                name: 'Maps',
                link: '/app/components/maps',
              },
            ]}
            glyph="components"
          />
        </React.Fragment>
      }
    </ul>}
  </nav>
);

function mapStateToProps(store) {
  const { auth } = store;
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    ...auth,
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(s)(Sidebar)));
