exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1fqjz2buWIUzrzyTQrFCu0{height:100%}._15WnwfQ6H4T0j8H2dCvUFO{min-height:100%;position:absolute;left:0;right:0;transition:left .3s ease-in-out,right .3s ease-in-out}@media (min-width:768px){._15WnwfQ6H4T0j8H2dCvUFO{left:200px;right:0}}@media (max-width:767.98px){.nHxq8SoTZzp0_IzhDLfi2{left:200px;right:-200px}}.sp2cJvb25OJbKgRt7nms7{min-height:100%;padding:115px 30px 60px;background-color:#f9f6f2}", ""]);

// exports
exports.locals = {
	"root": "_1fqjz2buWIUzrzyTQrFCu0",
	"wrap": "_15WnwfQ6H4T0j8H2dCvUFO",
	"sidebarOpen": "nHxq8SoTZzp0_IzhDLfi2",
	"content": "sp2cJvb25OJbKgRt7nms7"
};