import {
  FETCH_USER_REQUEST,
  FETCH_USER_RESOLVE,
  FETCH_USER_REJECT,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESOLVE,
  UPDATE_USER_REJECT,
  FETCH_USERS_REQUEST,
  FETCH_USERS_RESOLVE,
  FETCH_USERS_REJECT,
  FETCH_USER_SESSIONS_REQUEST,
  FETCH_USER_SESSIONS_RESOLVE,
  FETCH_USER_SESSIONS_REJECT,
} from '../actions/users';


export default function users(
  state = {
    user: null,
    users: [],
    sessions: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        user: null,
      };
    case FETCH_USER_RESOLVE:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
      };
    case FETCH_USER_REJECT:
      return {
        ...state,
        isFetching: false,
        user: null,
        message: action.payload[0].message,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_USER_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'User updated successfully',
      };
    case UPDATE_USER_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        users: [],
        isFetching: true,
      };
    case FETCH_USERS_RESOLVE:
      return {
        ...state,
        users: action.payload.users,
        isFetching: false,
      };
    case FETCH_USERS_REJECT:
      return {
        ...state,
        users: [],
        isFetching: false,
        message: action.payload[0].errors,
      };
    case FETCH_USER_SESSIONS_REQUEST:
      return {
        ...state,
        sessions: [],
        isFetching: true,
      };
    case FETCH_USER_SESSIONS_RESOLVE:
      return {
        ...state,
        sessions: action.payload.userSessions,
        isFetching: false,
      };
    case FETCH_USER_SESSIONS_REJECT:
      return {
        ...state,
        sessions: [],
        isFetching: false,
        message: action.payload[0].errors,
      };
    default:
      return state;
  }
}
