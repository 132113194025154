import { request } from './helpers';

export const FETCH_PLACE_USAGE = 'FETCH_PLACE_USAGE';
export const FETCH_PLACE_USAGE_REQUEST = 'FETCH_PLACE_USAGE_REQUEST';
export const FETCH_PLACE_USAGE_RESOLVE = 'FETCH_PLACE_USAGE_RESOLVE';
export const FETCH_PLACE_USAGE_REJECT = 'FETCH_PLACE_USAGE_REJECT';

export function fetchPlaceUsage(datetime) {
  return request({
    type: FETCH_PLACE_USAGE,
    query: `
    {
      placeUsage(date: "${datetime.toISOString()}") {
        datetime
        place
        zone
        available
        unavailable
      }
    }`,
  });
}
