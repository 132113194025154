
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';

import s from './Activate.scss';

class Activate extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <div className={cx(s.root, this.props.className)}>
        Your account is not activated. Contact the administrator.
      </div>
    );
  }
}

export default withStyles(s)(Activate);
