
export function request({ type, query, variables, ...config }) {
  return async (dispatch, getState, { graphqlRequest }) => {
    dispatch({
      ...config,
      type: `${type}_REQUEST`,
    });

    const payload = await graphqlRequest(query, variables);

    if (!payload || payload.errors) {
      dispatch({
        ...config,
        type: `${type}_REJECT`,
        payload: payload && payload.errors,
      });
      return new Error(payload && payload.errors);
    }

    dispatch({
      ...config,
      type: `${type}_RESOLVE`,
      payload: payload.data,
    });
    return payload;
  }
}

export default { request };
