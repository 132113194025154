import { request } from './helpers';

export const FETCH_GIZMO_ACTIVE_USER_SESSIONS = 'FETCH_GIZMO_ACTIVE_USER_SESSIONS';
export const FETCH_GIZMO_ACTIVE_USER_SESSIONS_REQUEST = 'FETCH_GIZMO_ACTIVE_USER_SESSIONS_REQUEST';
export const FETCH_GIZMO_ACTIVE_USER_SESSIONS_RESOLVE = 'FETCH_GIZMO_ACTIVE_USER_SESSIONS_RESOLVE';
export const FETCH_GIZMO_ACTIVE_USER_SESSIONS_REJECT = 'FETCH_GIZMO_ACTIVE_USER_SESSIONS_REJECT';

export function fetchActiveUserSessions() {
  return request({
    type: FETCH_GIZMO_ACTIVE_USER_SESSIONS,
    query: `{
      gizmoActiveUserSessions {
        userId
        username
        hostName
        userGroupId
        userGroupName
        hostGroupName
        lastLogin
        lastLogout
      }
    }`,
  });
}


export const FETCH_GIZMO_USERS = 'FETCH_GIZMO_USERS';
export const FETCH_GIZMO_USERS_REQUEST = 'FETCH_GIZMO_USERS_REQUEST';
export const FETCH_GIZMO_USERS_RESOLVE = 'FETCH_GIZMO_USERS_RESOLVE';
export const FETCH_GIZMO_USERS_REJECT = 'FETCH_GIZMO_USERS_REJECT';

export function fetchUsers() {
  return request({
    type: FETCH_GIZMO_USERS,
    query: `{
      gizmoUsers {
        id
        username
        email
        sex
        firstName
        lastName
        birthDate
        address
        city
        country
        postCode
        phone
        mobilePhone
        isDeleted
        isDisabled
        createdTime
        modifiedTime
      }
    }`,
  });
}


export const FETCH_GIZMO_POINTS = 'FETCH_GIZMO_POINTS';
export const FETCH_GIZMO_POINTS_REQUEST = 'FETCH_GIZMO_POINTS_REQUEST';
export const FETCH_GIZMO_POINTS_RESOLVE = 'FETCH_GIZMO_POINTS_RESOLVE';
export const FETCH_GIZMO_POINTS_REJECT = 'FETCH_GIZMO_POINTS_REJECT';

export function fetchPoints() {
  return request({
    type: FETCH_GIZMO_POINTS,
    query: `{
      gizmoPoints {
        id
        userId
        username
        type
        amount
        balance
        modifiedTime
        createdTime
      }
    }`,
  });
}


export const FETCH_GIZMO_NEWS = 'FETCH_GIZMO_NEWS';
export const FETCH_GIZMO_NEWS_REQUEST = 'FETCH_GIZMO_NEWS_REQUEST';
export const FETCH_GIZMO_NEWS_RESOLVE = 'FETCH_GIZMO_NEWS_RESOLVE';
export const FETCH_GIZMO_NEWS_REJECT = 'FETCH_GIZMO_NEWS_REJECT';

export function fetchNews() {
  return request({
    type: FETCH_GIZMO_NEWS,
    query: `{
      gizmoNews {
        id
        title
        url
        data
        startDate
        endDate
      }
    }`,
  });
}


export const FETCH_GIZMO_USER_STATS = 'FETCH_GIZMO_USER_STATS';
export const FETCH_GIZMO_USER_STATS_REQUEST = 'FETCH_GIZMO_USER_STATS_REQUEST';
export const FETCH_GIZMO_USER_STATS_RESOLVE = 'FETCH_GIZMO_USER_STATS_RESOLVE';
export const FETCH_GIZMO_USER_STATS_REJECT = 'FETCH_GIZMO_USER_STATS_REJECT';

export function fetchUserStats() {
  return request({
    type: FETCH_GIZMO_USER_STATS,
    query: `{
      gizmoUsersStats {
        value
        name
        count
      }
    }`,
  });
}


export const FETCH_GIZMO_POINT_STATS = 'FETCH_GIZMO_POINT_STATS';
export const FETCH_GIZMO_POINT_STATS_REQUEST = 'FETCH_GIZMO_POINT_STATS_REQUEST';
export const FETCH_GIZMO_POINT_STATS_RESOLVE = 'FETCH_GIZMO_POINT_STATS_RESOLVE';
export const FETCH_GIZMO_POINT_STATS_REJECT = 'FETCH_GIZMO_POINT_STATS_REJECT';

export function fetchPointStats() {
  return request({
    type: FETCH_GIZMO_POINT_STATS,
    query: `{
      gizmoPointsStats {
        value
        name
        count
      }
    }`,
  });
}
