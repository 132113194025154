import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Input,
} from 'reactstrap';
import moment from 'moment';
import Color from 'color';
import {
  ResponsiveContainer,
  Cell, Tooltip, ReferenceLine,
  Line, Bar, XAxis, YAxis, CartesianGrid,
  BarChart, ComposedChart
} from 'recharts';

import Widget from '../../components/Widget/Widget';
import { fetchSessions } from '../../actions/sessions';

import s from './Dashboard.scss';

const shiftStatus = (startDate, endDate) => moment(startDate).date() === moment(endDate).date() ? 'day' : 'night';
const getMonths  = (sessions) => sessions.map(s => moment(s.endDate).startOf('month').valueOf()).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a < b ? 1 : -1);

class Dashboard extends Component {
  /* eslint-disable */
  static propTypes = {
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    isFetching: false,
  };

  constructor(props) {
    super(props);

    const months = getMonths(this.props.sessions);
    this.state = {
      months,
      month: months[0],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchSessions());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessions != this.props.sessions) {
      const months = getMonths(this.props.sessions);
      this.setState(state => ({
        months,
        month: state.month || months[0],
      }))
    }
  }

  onMonthChange = (e) => {
    this.setState({
      month: Number(e.target.value),
    });
  }

  filterSessions = (sessions, month) => {
    const date = moment(month).format('MMMM YYYY');
    return sessions.filter(s => moment(s.startDate).format('MMMM YYYY') == date && s.status !== 'SERVICE');
  }


  calcSessions = (sessions) =>
    sessions.reduce((prev, s) => {
      const shift = shiftStatus(s.startDate, s.endDate);
      const f = prev.find(ses => ses.userId === s.user.id);
      const duration = moment.duration(moment(s.endDate).diff(s.startDate)).asMinutes()
      if (f) {
        f.total++;
        f.bonus += s.bonusAmount;
        f.bonusAvg = +(f.bonus / f.total).toFixed(2);
        f.duration += +(duration / 60).toFixed(2);
        f.durationAvg = +(f.duration / f.total).toFixed(2);
        if (shift === 'day') {
          f.day++;
        } else {
          f.night++;
        }
        f.ratio = +(f.day / f.total).toFixed(2);
        f.balance += Math.abs(s.balanceAmount);
        f.balanceAvg = +(f.balance / f.total).toFixed(2);
        if (s.balanceAmount > 0) {
          f.plus += s.balanceAmount;
        } else {
          f.minus += Math.abs(s.balanceAmount);
        }
        return prev;
      }

      return [
        ...prev,
        {
          userId: s.user.id,
          name: s.user.profile.displayName,
          color: `#${s.user.color}`,
          total: 1,
          ratio: shift === 'day' ? 1 : 0,
          day: shift === 'day' ? 1 : 0,
          night: shift === 'night' ? 1 : 0,
          bonus: s.bonusAmount,
          bonusAvg: s.bonusAmount,
          balance: Math.abs(s.balanceAmount),
          balanceAvg: Math.abs(s.balanceAmount),
          plus: s.balanceAmount > 0 ? s.balanceAmount : 0,
          minus: s.balanceAmount < 0 ? Math.abs(s.balanceAmount) : 0,
          duration: +(duration / 60).toFixed(2),
          durationAvg: +(duration / 60).toFixed(2),
        },
      ];
    },
    []);

  render() {
    const sessions = this.filterSessions(this.props.sessions, this.state.month);
    const calc = this.calcSessions(sessions);

    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>SkillBoard</BreadcrumbItem>
          <BreadcrumbItem active>Dashboard</BreadcrumbItem>
        </Breadcrumb>
        <Widget
          className="pb-0"
          title={
            <div>
              <div className="pull-right mt-n-xs mr-md">
                <div className="input-group flex-nowrap">
                  <i className="fa fa-calendar-o mr-xs mt-sm" />
                  <Input type="select" bsSize="sm" name="filter-month" value={this.state.month} onChange={this.onMonthChange}>
                    {this.state.months.map(date =>
                      <option key={date} value={date}>{moment(date).format('MMMM YYYY')}</option>
                    )}
                  </Input>
                </div>
              </div>
              <h5 className="pull-left mt-0">
                <i className="fa fa-home mr-xs opacity-70" />{' '}
                Statistics
              </h5>
            </div>
          }
        >
          <Row>
            <Col xs={12} xl={6}>
              <Widget
                style={{ height: 300 }}
                title={<h5>Total <span className="fw-semi-bold">Shifts</span></h5>}
              >
                <ResponsiveContainer>
                  <ComposedChart
                    height={300}
                    data={calc}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <ReferenceLine yAxisId="left" y={calc && (calc.reduce((sum, c) => sum + c.total, 0) / calc.length)} label="Avg Shifts" stroke="#8884d8" strokeDasharray="10 10" isFront />
                    <ReferenceLine yAxisId="right" y={calc && (calc.reduce((sum, c) => sum + c.ratio, 0) / calc.length)} label="Avg Ratio" stroke="#ff7300" strokeDasharray="10 10" isFront />
                    <Bar yAxisId="left" dataKey="day" fill="#ff7300" name="Days" stackId="a">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={Color(c.color).lighten(0.1)} />
                      )}
                    </Bar>
                    <Bar yAxisId="left" dataKey="night" fill="#8884d8" name="Nights" stackId="a">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={Color(c.color).darken(0.1)} />
                      )}
                    </Bar>
                    <Line yAxisId="right" type="monotone" dataKey="ratio" stroke="#ff7300" name="Ratio [days / nights]" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Widget>
            </Col>

            <Col xs={12} xl={6}>
              <Widget
                style={{ height: 300 }}
                title={<h5>Total <span className="fw-semi-bold">Duration</span></h5>}
              >
                <ResponsiveContainer>
                  <ComposedChart
                    height={300}
                    data={calc}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <ReferenceLine yAxisId="left" y={calc && (calc.reduce((sum, c) => sum + c.duration, 0) / calc.length)} label="Avg Duration" stroke="#8884d8" strokeDasharray="10 10" isFront />
                    <ReferenceLine yAxisId="right" y={calc && (calc.reduce((sum, c) => sum + c.durationAvg, 0) / calc.length)} label="Avg Duration per Shift" stroke="#ff7300" strokeDasharray="10 10" isFront />
                    <Bar yAxisId="left" dataKey="duration" fill="#8884d8" name="Duration [h]">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={c.color} />
                      )}
                    </Bar>
                    <Line yAxisId="right" type="monotone" dataKey="durationAvg" stroke="#ff7300" name="Avg Duration per Shift [h]" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Widget>
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={6}>
              <Widget
                style={{ height: 300 }}
                title={<h5>Total <span className="fw-semi-bold">Bonus</span></h5>}
              >
                <ResponsiveContainer>
                  <ComposedChart
                    height={300}
                    data={calc}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <ReferenceLine yAxisId="left" y={calc && (calc.reduce((sum, c) => sum + c.bonus, 0) / calc.length)} label="Avg Bonus" stroke="#8884d8" strokeDasharray="10 10" isFront />
                    <ReferenceLine yAxisId="right" y={calc && (calc.reduce((sum, c) => sum + c.bonusAvg, 0) / calc.length)} label="Avg Bonus per Shift" stroke="#ff7300" strokeDasharray="10 10" isFront />
                    <Bar yAxisId="left" dataKey="bonus" fill="#8884d8" name="Bonus [Kč]">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={c.color} />
                      )}
                    </Bar>
                    <Line yAxisId="right" type="monotone" dataKey="bonusAvg" stroke="#ff7300" name="Avg Bonus per Shift [Kč]" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Widget>
            </Col>

            <Col xs={12} xl={6}>
              <Widget
                style={{ height: 300 }}
                title={<h5>Total <span className="fw-semi-bold">Balance</span></h5>}
              >
                <ResponsiveContainer>
                  <ComposedChart
                    height={300}
                    data={calc}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <ReferenceLine yAxisId="left" y={calc && (calc.reduce((sum, c) => sum + c.balance, 0) / calc.length)} label="Avg Balance" stroke="#8884d8" strokeDasharray="10 10" isFront />
                    <ReferenceLine yAxisId="right" y={calc && (calc.reduce((sum, c) => sum + c.balanceAvg, 0) / calc.length)} label="Avg Balance per Shift" stroke="#ff7300" strokeDasharray="10 10" isFront />
                    <Bar yAxisId="left" dataKey="plus" fill="orange" name="Plus [Kč]" stackId="a">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={Color(c.color).lighten(0.1)} />
                      )}
                    </Bar>
                    <Bar yAxisId="left" dataKey="minus" fill="lightcoral" name="Minus [Kč]" stackId="a">
                      {calc.map(c =>
                        <Cell key={c.userId} fill={Color(c.color).darken(0.1)} />
                      )}
                    </Bar>
                    <Line yAxisId="right" type="monotone" dataKey="balanceAvg" stroke="#ff7300" name="Avg Balance per Shift [Kč]" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Widget>
            </Col>
          </Row>

        </Widget>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.sessions,
  };
}

export default connect(mapStateToProps)(withStyles(s)(Dashboard));
