import {
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_RESOLVE,
  CREATE_SESSION_REJECT,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_RESOLVE,
  UPDATE_SESSION_REJECT,
  SET_SESSION_STATUS_REQUEST,
  SET_SESSION_STATUS_RESOLVE,
  SET_SESSION_STATUS_REJECT,
  REMOVE_SESSION_REQUEST,
  REMOVE_SESSION_RESOLVE,
  REMOVE_SESSION_REJECT,
  RECALC_SESSION_REQUEST,
  RECALC_SESSION_RESOLVE,
  RECALC_SESSION_REJECT,
  RECALC_STATUS_REQUEST,
  RECALC_STATUS_RESOLVE,
  RECALC_STATUS_REJECT,
  FETCH_SESSIONS_REQUEST,
  FETCH_SESSIONS_RESOLVE,
  FETCH_SESSIONS_REJECT,
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_RESOLVE,
  FETCH_SESSION_REJECT,
  FETCH_SESSION_PRODUCTS_REQUEST,
  FETCH_SESSION_PRODUCTS_RESOLVE,
  FETCH_SESSION_PRODUCTS_REJECT,
  FETCH_SESSION_HOST_USAGE_REQUEST,
  FETCH_SESSION_HOST_USAGE_RESOLVE,
  FETCH_SESSION_HOST_USAGE_REJECT,
  FETCH_SESSION_USER_USAGE_REQUEST,
  FETCH_SESSION_USER_USAGE_RESOLVE,
  FETCH_SESSION_USER_USAGE_REJECT,
} from '../actions/sessions';

export default function sessions(
  state = {
    session: null,
    sessionProducts: [],
    sessions: [],
    isRecalc: false,
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case CREATE_SESSION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_SESSION_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'Session created successfully',
      };
    case CREATE_SESSION_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case UPDATE_SESSION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_SESSION_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'Session updated successfully',
      };
    case UPDATE_SESSION_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case SET_SESSION_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_SESSION_STATUS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'Session updated successfully',
      };
    case SET_SESSION_STATUS_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case REMOVE_SESSION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case REMOVE_SESSION_RESOLVE:
      return {
        ...state,
        sessions: state.sessions.filter(session => session.id !== action.payload.removeSession.id),
        isFetching: false,
        message: 'Session removed successfully',
      };
    case REMOVE_SESSION_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case RECALC_SESSION_REQUEST:
      return {
        ...state,
      };
    case RECALC_SESSION_RESOLVE:
      return {
        ...state,
        isRecalc: !action.payload.recalcSession
      };
    case RECALC_SESSION_REJECT:
      return {
        ...state,
        isRecalc: false,
        message: action.payload[0].message,
      };
    case RECALC_STATUS_REQUEST:
      return {
        ...state,
      };
    case RECALC_STATUS_RESOLVE:
      return {
        ...state,
        isRecalc: !action.payload.recalcSession
      };
    case RECALC_STATUS_REJECT:
      return {
        ...state,
        isRecalc: false,
        message: action.payload[0].message,
      };
    case FETCH_SESSIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        // sessions: [],
      };
    case FETCH_SESSIONS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        sessions: action.payload.sessions,
      };
    case FETCH_SESSIONS_REJECT:
      return {
        ...state,
        isFetching: false,
        sessions: [],
        message: action.payload[0].message,
      };
    case FETCH_SESSION_REQUEST:
      return {
        ...state,
        isFetching: true,
        session: null,
      };
    case FETCH_SESSION_RESOLVE:
      return {
        ...state,
        isFetching: false,
        session: action.payload.session,
      };
    case FETCH_SESSION_REJECT:
      return {
        ...state,
        isFetching: false,
        session: null,
        message: action.payload[0].message,
      };
    case FETCH_SESSION_PRODUCTS_REQUEST:
      return {
        ...state,
        sessionProducts: [],
      };
    case FETCH_SESSION_PRODUCTS_RESOLVE:
      return {
        ...state,
        sessionProducts: action.payload.sessionProducts,
      };
    case FETCH_SESSION_PRODUCTS_REJECT:
      return {
        ...state,
        sessionProducts: [],
        message: action.payload[0].message,
      };
    case FETCH_SESSION_HOST_USAGE_REQUEST:
      return {
        ...state,
        sessionHostUsage: [],
      };
    case FETCH_SESSION_HOST_USAGE_RESOLVE:
      return {
        ...state,
        sessionHostUsage: action.payload.sessionHostUsage,
      };
    case FETCH_SESSION_HOST_USAGE_REJECT:
      return {
        ...state,
        sessionHostUsage: [],
        message: action.payload[0].message,
      };
      case FETCH_SESSION_USER_USAGE_REQUEST:
        return {
          ...state,
          sessionUserUsage: [],
        };
      case FETCH_SESSION_USER_USAGE_RESOLVE:
        return {
          ...state,
          sessionUserUsage: action.payload.sessionUserUsage,
        };
      case FETCH_SESSION_USER_USAGE_REJECT:
        return {
          ...state,
          sessionUserUsage: [],
          message: action.payload[0].message,
        };
    default:
      return state;
  }
}
