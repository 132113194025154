import { request } from './helpers';


export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_RESOLVE = 'CREATE_SESSION_RESOLVE';
export const CREATE_SESSION_REJECT = 'CREATE_SESSION_REJECT';

export function createSession(postData) {
  return request({
    type: CREATE_SESSION,
    query: `mutation CreateSession($input: SessionInput!) {
      addSession(input: $input){
        id
      }
    }`,
    variables: {
      input: postData,
    }
  });
}


export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST';
export const UPDATE_SESSION_RESOLVE = 'UPDATE_SESSION_RESOLVE';
export const UPDATE_SESSION_REJECT = 'UPDATE_SESSION_REJECT';

export function updateSession(postData) {
  return request({
    type: UPDATE_SESSION,
    query: `mutation UpdateSession($input: SessionInput!) {
      updateSession(input: $input){
        id
      }
    }`,
    variables: {
      input: postData,
    }
  });
}


export const SET_SESSION_STATUS = 'SET_SESSION_STATUS';
export const SET_SESSION_STATUS_REQUEST = 'SET_SESSION_STATUS_REQUEST';
export const SET_SESSION_STATUS_RESOLVE = 'SET_SESSION_STATUS_RESOLVE';
export const SET_SESSION_STATUS_REJECT = 'SET_SESSION_STATUS_REJECT';

export function setSessionStatus(id, status) {
  return request({
    type: SET_SESSION_STATUS,
    query: `mutation SetSessionStatus($id: ID!, $status: String!) {
      setSessionStatus(id: $id, status: $status){
        id
      }
    }`,
    variables: {
      id,
      status,
    }
  });
}


export const REMOVE_SESSION = 'REMOVE_SESSION';
export const REMOVE_SESSION_REQUEST = 'REMOVE_SESSION_REQUEST';
export const REMOVE_SESSION_RESOLVE = 'REMOVE_SESSION_RESOLVE';
export const REMOVE_SESSION_REJECT = 'REMOVE_SESSION_REJECT';

export function removeSession(id) {
  return request({
    type: REMOVE_SESSION,
    query: `mutation RemoveSession($id: ID!) {
      removeSession(id: $id){
        id
      }
    }`,
    variables: {
      id,
    }
  });
}


export const RECALC_SESSION = 'RECALC_SESSION';
export const RECALC_SESSION_REQUEST = 'RECALC_SESSION_REQUEST';
export const RECALC_SESSION_RESOLVE = 'RECALC_SESSION_RESOLVE';
export const RECALC_SESSION_REJECT = 'RECALC_SESSION_REJECT';

export function recalcSession(startDate, endDate) {
  return request({
    type: RECALC_SESSION,
    query: `mutation RecalcSession($startDate: DateTime! $endDate: DateTime!) {
      recalcSession(
        startDate: $startDate
        endDate: $endDate
      )
    }`,
    variables: {
      startDate,
      endDate,
    }
  });
}

export const RECALC_STATUS = 'RECALC_STATUS';
export const RECALC_STATUS_REQUEST = 'RECALC_STATUS_REQUEST';
export const RECALC_STATUS_RESOLVE = 'RECALC_STATUS_RESOLVE';
export const RECALC_STATUS_REJECT = 'RECALC_STATUS_REJECT';

export function recalcStatus() {
  return request({
    type: RECALC_STATUS,
    query: `mutation {
      recalcSession
    }`
  });
}

export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const FETCH_SESSIONS_REQUEST = 'FETCH_SESSIONS_REQUEST';
export const FETCH_SESSIONS_RESOLVE = 'FETCH_SESSIONS_RESOLVE';
export const FETCH_SESSIONS_REJECT = 'FETCH_SESSIONS_REJECT';

export function fetchSessions() {
  return request({
    type: FETCH_SESSIONS,
    query: `{
      sessions {
        id
        description
        privateDescription
        startDate
        endDate
        cashRegister
        salaryAdvance
        money
        startAmount
        registerAmount
        envelopeAmount
        gizmoAmount
        failAmount
        invoiceAmount
        balanceAmount
        bonusAmount
        status
        fails {
          amount
          description
        }
        invoices {
          amount
          description
        }
        bonus {
          type
          amount
          description
        }
        user {
          id
          color
          profile {
            displayName
            picture
          }
        }
      }
    }`,
  });
}

export const FETCH_SESSION = 'FETCH_SESSION';
export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_RESOLVE = 'FETCH_SESSION_RESOLVE';
export const FETCH_SESSION_REJECT = 'FETCH_SESSION_REJECT';

export function fetchSession(id) {
  return request({
    type: FETCH_SESSION,
    query: `{
      session(id: "${id}") {
        id
        description
        privateDescription
        startDate
        endDate
        cashRegister
        salaryAdvance
        money
        startAmount
        registerAmount
        envelopeAmount
        gizmoAmount
        failAmount
        invoiceAmount
        balanceAmount
        bonusAmount
        status
        fails {
          amount
          description
        }
        invoices {
          amount
          description
        }
        bonus {
          type
          amount
          description
        }
        user {
          id
          color
          profile {
            displayName
            picture
          }
        }
      }
    }`,
  });
}


export const FETCH_SESSION_PRODUCTS = 'FETCH_SESSION_PRODUCTS';
export const FETCH_SESSION_PRODUCTS_REQUEST = 'FETCH_SESSION_PRODUCTS_REQUEST';
export const FETCH_SESSION_PRODUCTS_RESOLVE = 'FETCH_SESSION_PRODUCTS_RESOLVE';
export const FETCH_SESSION_PRODUCTS_REJECT = 'FETCH_SESSION_PRODUCTS_REJECT';

export function fetchSessionProducts(sessuinId) {
  return request({
    type: FETCH_SESSION_PRODUCTS,
    query: `
    {
      sessionProducts(sessionId: "${sessuinId}") {
        datetime
        username
        productName
        groupName
        unitPrice
        quantity
        total
        bonus
      }
    }`,
  });
}

export const FETCH_SESSION_HOST_USAGE = 'FETCH_SESSION_HOST_USAGE';
export const FETCH_SESSION_HOST_USAGE_REQUEST = 'FETCH_SESSION_HOST_USAGE_REQUEST';
export const FETCH_SESSION_HOST_USAGE_RESOLVE = 'FETCH_SESSION_HOST_USAGE_RESOLVE';
export const FETCH_SESSION_HOST_USAGE_REJECT = 'FETCH_SESSION_HOST_USAGE_REJECT';

export function fetchSessionHostUsage(sessionId) {
  return request({
    type: FETCH_SESSION_HOST_USAGE,
    query: `
    {
      sessionHostUsage(sessionId: "${sessionId}") {
        datetime
        groupId
        groupName
        usage
        count
      }
    }`,
  });
}

export const FETCH_SESSION_USER_USAGE = 'FETCH_SESSION_USER_USAGE';
export const FETCH_SESSION_USER_USAGE_REQUEST = 'FETCH_SESSION_USER_USAGE_REQUEST';
export const FETCH_SESSION_USER_USAGE_RESOLVE = 'FETCH_SESSION_USER_USAGE_RESOLVE';
export const FETCH_SESSION_USER_USAGE_REJECT = 'FETCH_SESSION_USER_USAGE_REJECT';

export function fetchSessionUserUsage(sessionId) {
  return request({
    type: FETCH_SESSION_USER_USAGE,
    query: `
    {
      sessionUserUsage(sessionId: "${sessionId}") {
        datetime
        groupId
        groupName
        usage
        count
      }
    }`,
  });
}
