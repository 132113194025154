exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IaSK9_zteQvORXEtv3QtO{position:fixed;left:0;top:0;bottom:0;padding-top:25vh;width:100%;height:100%;background:linear-gradient(90deg,#eb3349,#f45c43);color:#4d4d4d}.PZ45xH9i6UwwU6lO7Z3gD{margin-bottom:30px}._3ANqIkJUnh9e2OUq4_JPEc{max-width:360px;margin:0 auto;padding:30px!important}._3ANqIkJUnh9e2OUq4_JPEc .form-control{font-size:14px}", ""]);

// exports
exports.locals = {
	"root": "_1IaSK9_zteQvORXEtv3QtO",
	"logo": "PZ45xH9i6UwwU6lO7Z3gD",
	"widget": "_3ANqIkJUnh9e2OUq4_JPEc"
};